<template>
    <div class="container_detail" style="background: #1c1c1c;">
        <div class="header_Css">
            <div @click="toBack"><img src="../assets/back.png" alt=""></div>
            <h2>Fundo de Resgate</h2>
            <span @click="toUrl('/home/records?num=8')">Histórico</span>
        </div>
        <!-- <div style="height: 10px;"></div> -->
        <div class="JYJJ_css" style="margin: 10px">
            <div class="JYJJ_css_1">
                <span>Perda de ontem <span v-if="resumeDetail.lastWeekLost>0" style="color: green;">{{ resumeDetail.lastWeekLost }}</span><span v-else style="color: green;">0</span></span>
                &nbsp;&nbsp; <span>Subsídio de hoje <span style="color: #feaf75;">{{ resumeDetail.reward }}</span>
                </span>
            </div>
            <table>
                <tr style="border: 1px solid #616161;background: #303030;">
                    <td style="width: 50%">Montante da perda</td>
                    <td style="width: 50%">Prêmios Extra</td>
                </tr>
                <tr :data="index" v-for="(item,index) in boxList.itemList">
                    <td style="width: 50%">≥ {{item.lossAmount |formatNumber}}</td>
                    <td style="color: #FFAA09;width: 50%">{{item.rescueAmount |formatNumber}}</td>
                </tr>
                <!-- <tr style="border: 1px solid #616161;background: #303030;">

                    <td>≥ 50</td>
                    <td style="color: #FFAA09;">2,00</td>
                </tr> -->

            </table>

            <div class="JYJJ_css_2">
                <div class="JYJJ_css_2_1">
                    <span style="font-weight: 700;padding-left: 5px;padding-top: 5px;">Instruções Do Evento:</span>
                    <div style="padding: 5px;text-align: justify">
                        1. Esta atividade pertence ao “resgate humanitário e resgate de emergência em rios e lagos”. Quando você perdeu 10 ou mais no jogo ontem, você pode obter a quantia correspondente em dinheiro de troca. Os níveis de bônus são determinados pela quantidade de perdas. Quanto maior a perda, maior o valor do resgate, até 7.777;
                        <br>
                        2. Não há limite de perdas na plataforma. A atualização da recompensa deverá ser realizada por volta das 02h de amanhã. Aguarde a distribuição da recompensa.
                        <br>
                        3. Fórmula de cálculo: valor do bônus = valor da perda no período (descontados os descontos), correspondente ao nível do bônus;
                        <br>
                        4. Os prêmios recebidos só poderão ser resgatados após as 07:00:00 de amanhã. O resgate só poderá ser feito manualmente em APP/iOS, APP/Android, PC/Windows.
                        <br>
                        5. As recompensas recebidas expirarão 1 dia após o final de cada dia. As recompensas expiradas serão automaticamente canceladas;
                        <br>
                        6. O bônus deste evento (excluindo o prêmio principal) requer 3 apostas válidas antes de poder ser sacado, e as apostas não estão limitadas às plataformas de jogo;
                        <br>
                        7. Esta atividade está limitada às operações normais realizadas pelo titular da conta. É proibido leasing, utilização de plug-ins, robôs, apostas com contas diferentes, escovação mútua, arbitragem, interfaces, protocolos, exploração de brechas, controle de grupo e outros meios técnicos. Caso contrário, as recompensas serão canceladas ou deduzidas, a conta será congelada ou mesmo colocada na lista negra;
                        <br>
                        8. Para evitar diferenças na compreensão do texto, a plataforma reserva-se o direito de interpretação final deste evento.
                        <br></div>


                </div>
            </div>

            <div class="JYJJ_css_3">
                <div class="JYJJ_css_3_1" @click="toBack">
                    Retornar
                </div>
                <div v-if="resumeDetail.status!=1" class="JYJJ_css_3_2">
                    Coletar Tudo
                </div>
                <div v-if="resumeDetail.status==1" class="JYJJ_css_3_1" @click="receiveResume">
                    Coletar Tudo
                </div>
            </div>

        </div>
        <div style="height: 80px;"></div>


<!--        <div class="qqq" style="margin-bottom: 80px;">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动01.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动02.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动03.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动04.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动05.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动06.png" alt="">-->
<!--        </div>-->

    </div>
</template>

<script>
import { activityDetail , boxReward, getResumeDetail, receiveResume} from '@/api/index'
import {Toast} from "vant";


export default {
    data() {
        return {

            boxList: {
            },
            totalLine: 0,
          activityId:this.$route.query.id,
          resumeDetail:{}
        }
    },
    mounted() {
        this.getActivityDetail();
        this.getResumeDetail();
    },
    methods: {
        toUrl(url){this.$router.push(url)},
        getActivityDetail() {
            activityDetail(
                this.$route.query.id
            ).then(res => {
                this.boxList = res.data
                if (this.boxList) {
                    let plus = this.boxList.itemList.length % 4;

                    this.totalLine = parseInt(this.boxList.itemList.length / 4);
                    if (plus > 0) {
                        this.totalLine = this.totalLine + 1;
                    }
                    console.log(this.totalLine)
                }
            })
        },
      getResumeDetail(){
        getResumeDetail(
            this.$route.query.id
        ).then(res => {
          // this.boxList = res.data
          this.resumeDetail = res.data;
          // console.log(res)
        })
      },
      receiveResume(){
        receiveResume(this.activityId).then(res => {
          // this.boxList = res.data
          if (res.code==200){
            this.getResumeDetail();
            Toast.success('Sucesso na obtenção');
          }
          // console.log(res)
        })
      },
        toBack() {
            this.$router.push('/home/event')
        }
    }
}
</script>

<style lang="scss" scoped>
.container_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header_Css {
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;
    min-height: 40px;

    img {
        cursor: pointer;
    }

    h2 {
        color: #fff;
        font-size: 15px;
        width: 260px;
        text-align: center;
    }

    span {
        color: #feaf75;
        cursor: pointer;
    }
}

.JYJJ_css {
    .JYJJ_css_1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px 0;
    }

    table {
        text-align: center;
        width: 100%;

        td {
            height: 48px;
        }
    }

    .JYJJ_css_2 {
        margin: 10px 0;

        .JYJJ_css_2_1 {
            background: #303030;
        }
    }
    .JYJJ_css_3{
        height: 67px;
        width: 100%;
        background: #303030;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        .JYJJ_css_3_1{
            cursor: pointer;
            border-radius: 10px;
            color: #cb8d71;
            width: 180px;
            border: 1px solid #cb8d71;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
        .JYJJ_css_3_2{
            cursor: pointer;
            border-radius: 10px;
            color: #ccc;
            width: 180px;
            border: 1px solid #999;
            background: #999;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
    }
}

.qqq {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;

    .bom_img {
        width: 104px;
        height: 50px;
        margin: 0 5px
    }
}
td{
    width:50%
}
@media (min-width:751px) {
  .JYJJ_css_3{
    height: 67px;
    width: 435px !important;
    background: #303030;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

</style>
